<template>
	<div class="histogram" :id="id"></div>
</template>
<script>
import { Column } from '@antv/g2plot';

const data = [
	{
		type: '研究生',
		sales: 21,
		color: '#CD80A2',
	},
	{
		type: '本科',
		sales: 192,
		color: '#EB7BDA',
	},
	{
		type: '大专',
		sales: 69,
		color: '#42DAE3',
	},
	{
		type: '高中',
		sales: 38,
		color: '#68DBB2',
	},
	{
		type: '中专',
		sales: 8,
		color: '#FFB3CF',
	},
	{
		type: '初中',
		sales: 9,
		color: '#EF7CD8',
	},
];
export default {
	props: {
		id: {
			default: '',
			type: String,
		},
		wd: {
			default: () => [],
			type: Array,
		},
	},
	data() {
		return {
			columnPlot: null,
		};
	},
	methods: {
		initChart() {
			// const data = this.wd;
			this.columnPlot = new Column(this.id, {
				data: this.wd,
				xField: 'education',
				yField: 'count',
				appendPadding: 16,
				label: {
					// 可手动配置 label 数据标签位置
					position: 'top', // 'top', 'bottom', 'middle',
					// 配置样式
					style: {
						fill: '#FFFFFF',
					},
					content: (item) => item.countNum,
				},
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
					},
				},
				seriesField: 'education',
				color: data.map((item) => item.color),
				legend: false,
				minColumnWidth: 20,
				maxColumnWidth: 20,
				tooltip: {
					formatter: (datum) => {
						return { name: datum.education, value: datum.count / 4 > 6 ? datum.count : datum.count / 4 };
					},
				},
			});
			this.columnPlot.render();
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		wd: {
			handler() {
				this.$nextTick(() => {
					this.columnPlot && this.columnPlot.destroy();
					this.initChart();
				});
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.histogram {
	width: 100%;
	height: 100%;
}
</style>
