<template>
	<div class="histogram" :id="id"></div>
</template>
<script>
import { DualAxes } from '@antv/g2plot';
export default {
	props: {
		id: {
			default: '',
			type: String,
		},
		isGradual: {
			default: false,
			type: Boolean,
		},
		ageData: {
			default: () => [],
			type: Array,
		},
	},
	data() {
		return {
			columnPlot: null,
		};
	},
	methods: {
		initChart() {
			const data = this.ageData;
			const sales = data.map((item) => ({
				type: 'dataMarker',
				top: true,
				appendPadding: 20,
				position: [item.type, item.sales],
				point: {
					style: {
						fill: '#FF6BA2',
						stroke: '#FF6BA2',
					},
				},
				line: {
					length: 0,
				},
			}));
			const maxCount = data.map((item) => item.count).sort((a, b) => b - a);
			this.columnPlot = new DualAxes(this.id, {
				data: [data, data],
				xField: 'type',
				yField: ['sales', 'count'],
				yAxis: {
					sales: { max: maxCount[0] + 50 },
					count: { max: maxCount[0] + 50 },
				},
				geometryOptions: [
					{
						geometry: 'column',
						color: 'l(250) 0:#EC8B6B 1:#ED6E9F',
						columnWidthRatio: 0.4,
						label: {
							// 可手动配置 label 数据标签位置
							position: 'top', // 'top', 'bottom', 'middle',
							// 配置样式
							style: {
								fill: '#FFFFFF',
							},
							// layout: 'overlap',
						},
						minColumnWidth: 20,
						maxColumnWidth: 20,
						tooltip: {
							formatter: (datum) => {
								return { name: datum.type, value: datum.sales };
							},
						},
					},
					{
						geometry: 'line',
						smooth: true,
						color: '#ED6E9F',
						tooltip: false,
					},
				],
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
					},
				},
				legend: false,
				annotations: {
					count: sales,
				},
			});
			this.columnPlot.render();
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		ageData: {
			handler() {
				this.$nextTick(() => {
					this.columnPlot && this.columnPlot.destroy();
					this.initChart();
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.histogram {
	width: 100%;
	height: 100%;
}
</style>
