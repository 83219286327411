<template>
	<div class="main">
		<div class="header">
			<img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
			<img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
			<span class="header-txt">智惠妇儿</span>
			<!-- 关闭 刷新 -->
			<div class="idx-middlesx-box3">
				<div class="idx-middle-1">
					<img src="@/assets/image/zhfe/return.png" @click="getback" />
					<img src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
				</div>
			</div>
		</div>
		<div class="time-box">
			<Date></Date>
		</div>
		<div class="container">
			<div class="module module_1">
				<div class="badge badge_1"></div>
				<div class="badge badge_2"></div>
				<div class="badge badge_3"></div>
				<div class="badge badge_4"></div>
				<div class="headline-wrap">
					<div class="headline">
						<p class="headline-text">执委领办项目情况</p>
					</div>
				</div>
				<div class="module-content">
					<div class="content_1">
						<div class="headline">
							<div class="headline-content">
								<img src="@/assets/image/zhfe/icon/user.png" alt="" />
								执委
								<span class="number">{{ censusTotal }}</span>
								名
							</div>
							<div class="headline-operate">
								<div class="operate-detail" @click="$router.push({ path: '/zhfeZwmc' })">
									<img src="../../assets/image/zhfe/user_pink.png" alt="" />
									执委名册
								</div>
								<img src="@/assets/image/zhfe/buttonBg.png" alt="" />
							</div>
						</div>
						<div class="line"></div>
						<Ring :census="census" :total="censusTotal" />
					</div>
				</div>
			</div>
			<img class="lineImage" src="@/assets/image/zhfe/hometipsShu.png" alt="" />
			<div class="module module_6">
				<div class="badge badge_1"></div>
				<div class="badge badge_2"></div>
				<div class="badge badge_3"></div>
				<div class="badge badge_4"></div>
				<div class="headline-wrap">
					<div class="headline" style="cursor: pointer" @click="handleRouteJumps('/lingBan')">
						<p class="headline-text">领办项目展示</p>
					</div>
					<h5 class="title">{{ article && article.title }}</h5>
				</div>
				<div class="module-content">
					<p class="author">{{ article && article.ownerName }}</p>
					<div class="content">
						<div class="slide">
							<el-carousel
								ref="carousel"
								height="25vh"
								trigger="click"
								indicator-position="outside"
								:autoplay="false"
								:arrow="'never'"
							>
								<el-carousel-item v-for="item in articleImageList" :key="item.id">
									<el-image style="width: 100%; height: 100%" fit="fill" :src="item.dataDetailed"></el-image>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="article">
							<h4 class="headline">{{ article && article.introTitle }}</h4>
							<p class="text">
								{{ article && article.lingbanIntro }}
							</p>
							<h4 class="headline">{{ article && article.descTitle }}</h4>
							<p class="text">
								{{ article && article.lingbanDesc }}
							</p>
						</div>
					</div>
					<div class="paging">
						<img class="icon" src="@/assets/image/zhfe/prev.png" @click="prev()" />
						<span class="current">{{ currentIndex + 1 }}&nbsp;</span>
						<span class="total">/&nbsp;{{ articleList.length }}</span>
						<img class="icon" src="@/assets/image/zhfe/next.png" @click="next()" />
					</div>
				</div>
			</div>
			<div class="module module_2">
				<div class="badge badge_1"></div>
				<div class="badge badge_2"></div>
				<div class="badge badge_3"></div>
				<div class="badge badge_4"></div>
				<div class="headline-wrap">
					<div class="headline" style="cursor: pointer" @click="edit">
						<!-- <img class="icon" :src="iconImage" alt="" /> -->
						<p class="headline-text">社区妇联主席专兼挂情况</p>
					</div>
				</div>
				<div class="module-content">
					<Pie :pieData="pieData" :showLegend="true" :id="'pie_2'" />
				</div>
			</div>
			<div class="module module_3">
				<img src="@/assets/image/zhfe/hometipsLeft.png" alt="" />
			</div>
			<div class="module module_4">
				<div class="badge badge_1"></div>
				<div class="badge badge_2"></div>
				<div class="badge badge_3"></div>
				<div class="badge badge_4"></div>
				<div class="headline-wrap">
					<div class="headline">
						<p class="headline-text">执委学历情况</p>
					</div>
				</div>
				<div class="module-content">
					<Histogram :wd="wd" :id="'histogram_1'" />
				</div>
			</div>
			<div class="module module_5">
				<div class="badge badge_1"></div>
				<div class="badge badge_2"></div>
				<div class="badge badge_3"></div>
				<div class="badge badge_4"></div>
				<div class="headline-wrap">
					<div class="headline">
						<p class="headline-text">执委年龄情况</p>
					</div>
				</div>
				<div class="module-content">
					<GradualHistogram :ageData="ageData" :id="'histogram_2'" />
				</div>
			</div>
		</div>
		<el-dialog
			:visible.sync="editVisible"
			title=""
			class="import-dialog"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-title">{{ dialogTitle }}</div>
			<el-form
				ref="form"
				:inline="true"
				:model="form"
				label-position="right"
				label-width="150px"
				class="demo-form-inline dialog-form-inline"
			>
				<el-form-item
					v-for="(item, index) in form.data"
					:key="item.dataid"
					:label="item.dataName"
					:prop="'data.' + index + '.dataDetailed'"
					:rules="[
						{ required: true, message: '人数不能为空', trigger: 'blur' },
						{ type: 'number', message: '人数必须为数字值' },
					]"
				>
					<el-input v-model.number="item.dataDetailed" placeholder=""></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleConfirm">确定</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="editVisible = false" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import Date from '@/views/commonComponents/date.vue';
import Ring from './components/ring/index.vue';
import Pie from './components/pie/index.vue';
import Histogram from './components/histogram/index.vue';
import GradualHistogram from './components/gradual-histogram/index.vue';
import { listData, getCountByEducation, getCountBynativePlace, batchDataDetail } from '@/api/dtdata';
import { getage, getlingban } from '@/api/seedlings.js';
import { Validate, CatchError } from '@/utils/decorator';

export default {
	components: {
		Date,
		Ring,
		Pie,
		Histogram,
		GradualHistogram,
	},
	data() {
		return {
			iconImage: require('@/assets/image/zhfe/zhfelogo.png'),
			numbers: 0,
			pieData: [],
			wd: [],
			census: [],
			censusTotal: 0,
			currentIndex: 0,
			ageData: [],
			articleList: [],
			articleImageList: [],
			editVisible: false,
			form: { data: [] },
			dialogTitle: '',
		};
	},
	computed: {
		article() {
			return this.articleList[this.currentIndex];
		},
	},
	methods: {
		handleRouteJumps(path) {
			if (!this.existSystem()) {
				return false;
			}
			this.$router.push({ path });
		},
		refresh() {
			this.$router.go(0); //刷新当前页面
		},
		getback() {
			this.$router.go(-1); //刷新当前页面
		},
		changeCarousel(index) {
			this.currentIndex = index;
		},
		@Validate('form')
		@CatchError()
		handleConfirm() {
			batchDataDetail(this.form.data).then(async (res) => {
				if (res.code == 200) {
					this.editVisible = false;
					const { rows: project } = await listData({ dataModule: 'zwlb', dataTyp: 'zwlb_zhuxi' });
					this.pieData = project.map((item, index) => ({
						type: item.dataName,
						value: +item.dataDetailed,
						color: index === 0 ? '#FFB3CF' : index === 1 ? '#FF6BA2' : '#8E1B46',
					}));
				}
			});
		},
		async edit() {
			if (!this.existSystem()) {
				return false;
			}
			const { rows } = await listData({ dataModule: 'zwlb', dataTyp: 'zwlb_zhuxi' });
			this.form.data = rows.map((item) => ({ ...item, dataDetailed: +item.dataDetailed }));
			this.dialogTitle = '社区妇联主席专兼挂情况';
			this.editVisible = true;
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		*getData() {
			yield listData({ dataModule: 'zwlb', dataTyp: 'zwlb-zwsum' });
			yield getCountByEducation();
			yield getCountBynativePlace();
			yield getage();
			yield listData({ dataModule: 'zwlb', dataTyp: 'zwlb_zhuxi' });
			yield getlingban();
		},
		async getImages() {
			const { rows } = await listData({ dataModule: 'lbxm', dataTyp: `lbxm-pic-${this.article.id}` });
			this.articleImageList = rows;
		},
		async initData() {
			const getdatas = this.getData();
			const { rows } = await getdatas.next().value;
			this.numbers = rows[0].dataDetailed;
			const { data } = await getdatas.next().value;
			console.log(data);
			this.wd = data.map((item) => ({ ...item, count: item.countNum > 6 ? item.countNum : item.countNum * 4 }));
			const {
				data: { allNum, dgNativeNum, otherNativeNum },
			} = await getdatas.next().value;
			this.censusTotal = allNum;
			this.census = [
				{ type: `东莞户籍执委`, value: dgNativeNum },
				{ type: `市外户籍`, value: otherNativeNum },
			];
			const { data: ageData } = await getdatas.next().value;
			this.ageData = ageData.map((item) => ({ type: item.ageStr, sales: item.userCount, count: item.userCount }));
			const { rows: project } = await getdatas.next().value;
			this.pieData = project.map((item, index) => ({
				type: item.dataName,
				value: +item.dataDetailed,
				color: index === 0 ? '#FFB3CF' : index === 1 ? '#FF6BA2' : '#8E1B46',
			}));
			const { rows: articleList } = await getdatas.next().value;
			this.articleList = articleList;
			this.getImages();
		},
		prev() {
			if (this.currentIndex === 0) {
				this.currentIndex = this.articleList.length - 1;
			} else {
				this.currentIndex -= 1;
			}
			this.getImages();
		},
		next() {
			if (this.currentIndex === this.articleList.length - 1) {
				this.currentIndex = 0;
			} else {
				this.currentIndex += 1;
			}
			this.getImages();
		},
	},
	mounted() {
		this.initData();
	},
};
</script>
<style lang="scss" scoped>
@import '../zhfeHome/index.scss';
.main {
	overflow: hidden;
}
.container {
	padding: 2vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	.module {
		border: 2px solid #fcd2e2;
		position: relative;
		.badge {
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 8px;
				left: -6px;
				width: 30px;
				height: 2px;
				background-color: #fcd2e2;
				transform: rotate(-45deg);
			}
			&:after {
				top: 16px;
				left: -8px;
				width: 52px;
			}
			&.badge_1 {
				position: absolute;
				top: 0;
				left: 0;
			}
			&.badge_2 {
				position: absolute;
				top: 0;
				right: 36px;
				&:before,
				&:after {
					top: 16px;
					left: -8px;
					transform: rotate(45deg);
				}
				&:before {
					left: 12px;
					top: 8px;
				}
			}
			&.badge_3 {
				position: absolute;
				bottom: 36px;
				left: 0;
				&:before,
				&:after {
					top: 18px;
					left: -8px;
					transform: rotate(45deg);
				}
				&:before {
					left: -6px;
					top: 26px;
				}
			}
			&.badge_4 {
				position: absolute;
				bottom: 36px;
				right: 36px;
				&:before,
				&:after {
					top: 18px;
					left: -8px;
				}
				&:before {
					left: 12px;
					top: 26px;
				}
			}
		}
		.headline-wrap {
			display: flex;
			justify-content: center;

			.headline {
				min-width: 10vw;
				width: max-content;
				padding: 12px 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 5vh;
				background: #cd80a2;
				border-radius: 0px 0px 14px 14px;
				background-size: 16px 16px;
				border: 2px solid #fcd2e2;
				border-top: none;

				.icon {
					width: 30px;
					height: 30px;
					margin-right: 12px;
				}
				.headline-text {
					font-family: 'Microsoft YaHei';
					font-weight: bold;
					font-size: 1.2vw;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}
		&.module_1 {
			width: 26%;
			height: 44vh;
			position: relative;
			border-top: none;
			.badge_1,
			.badge_2 {
				top: -4.8vh;
			}
			.headline-wrap {
				position: absolute;
				border: 2px solid #fcd2e2;
				border-bottom: none;
				top: -5vh;
				left: -2px;
				width: calc(100% + 4px);
			}
			.module-content {
				display: flex;
				align-items: center;
				height: 100%;
				.content_1,
				.content_2 {
					height: 100%;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					&.content_1 {
						width: 100%;

						.headline {
							width: 100%;
							display: flex;
							justify-content: center;
							flex-direction: column;
							align-items: center;
							color: #fff;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 600;
							color: #ffffff;
							.headline-content {
								display: flex;
								align-items: center;
								padding-top: 10px;
								img {
									display: block;
									margin-right: 12px;
								}
								.number {
									margin: 0 12px;
									font-size: 30px;
									font-family: 'hy';
									color: #e6598e;
								}
							}
							.headline-operate {
								position: relative;
								margin-top: 2vh;
								.operate-detail {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 14px;
									font-family: Microsoft YaHei;
									font-weight: bold;
									color: #d5f5fe;
									cursor: pointer;
									padding-top: 6px;
								}
							}
						}
					}
					&.content_2 {
						flex: 1;
						.legend-wrap {
							display: grid;
							grid-template-columns: repeat(3, 1fr);
							gap: 0 1vw;
							padding: 0 1vw 3vh;
							.legend-item {
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: column;
								padding: 8px 0;
								color: #fff;
								font-size: 1rem;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #ffffff;
								font-weight: 600;

								.label {
									margin-bottom: 8px;
									display: flex;
									align-items: flex-end;
									.number {
										font-size: 1.4rem;
										font-family: 'hy';
										font-style: italic;
										color: #ffffff;
										margin-right: 4px;
										display: inline-block;
									}
									.unit {
										display: inline-block;
									}
								}
							}
						}
					}
				}
				.line {
					width: 80%;
					height: 2px;
					background: linear-gradient(
						to left,
						rgba(255, 255, 255, 0.1),
						rgba(255, 255, 255, 0.37),
						rgba(255, 255, 255, 0.1)
					);
					margin: 0 auto;
				}
			}
		}
		&.module_2 {
			width: 26%;
			height: 44vh;
			.module-content {
				height: calc(100% - 62px);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&.module_3 {
			width: 100%;
			height: 2vh;
			border: none;
			margin: 20px auto;
			text-align: center;
		}
		&.module_4 {
			width: 46%;
			height: 32vh;
			.module-content {
				width: 100%;
				overflow: hidden;
				height: calc(100% - 82px);
			}
		}
		&.module_5 {
			width: 46%;
			height: 32vh;
			.module-content {
				width: 100%;
				overflow: hidden;
				height: calc(100% - 82px);
			}
		}
		&.module_6 {
			width: 44%;
			height: 44vh;
			.badge_1,
			.badge_3 {
				display: none;
			}

			.headline-wrap {
				justify-content: flex-start;
				align-items: center;
				.headline {
					min-width: auto;
					margin-left: 1vw;
				}
				.title {
					font-size: 1.2vw;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #ff6ba2;
					margin-left: 1vw;
				}
			}

			.module-content {
				width: 100%;
				height: 39vh;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 1vh 0;
				.author {
					font-size: 13px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					margin: 0 auto;
					text-align: center;
					margin-bottom: 2vh;
				}
				.content {
					width: 100%;
					padding: 1vw;
					display: flex;
					align-items: center;
					/* margin-top: 1vh; */
					flex: 1;
					.slide,
					.article {
						width: 50%;
						height: 30vh;
						&.slide {
							background-color: rgba(205, 128, 162, 0.2);
							text-align: center;
							padding: 2vh 20px 0;
							::v-deep .el-carousel__indicators {
								display: flex;
								align-items: center;
								justify-content: center;
								width: fit-content;
								left: 20px;
								background-color: rgba(205, 128, 162, 0.68);
								padding: 4px;
								border-radius: 20px;
								margin-top: 4px;
								.el-carousel__indicator {
									width: 7px;
									height: 7px;
									background: #f8f8f8;
									border-radius: 20px;
									padding: 0;
									margin-right: 3px;
									overflow: hidden;
									&.is-active {
										width: 20px;
										background: #f8f8f8;
									}
									.el-carousel__button {
										width: 100%;
										height: 100%;
										background: #f8f8f8;
									}
								}
							}
						}
						&.article {
							overflow-y: auto;
							padding-left: 1vw;
							.headline {
								font-size: 18px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #c25180;
								margin-bottom: 8px;
							}
							.text {
								font-size: 13px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								line-height: 1.4;
								&:first-of-type {
									margin-bottom: 18px;
								}
							}
						}
					}
				}
				.paging {
					display: flex;
					align-items: center;
					justify-content: center;
					/* margin-top: 0.5vh; */
					.icon {
						width: 32px;
						display: block;
						margin: 0 1vw;
						cursor: pointer;
					}
					.current {
						font-size: 32px;
						font-family: 'hy';
						font-weight: 400;
						font-style: italic;
						color: #ffd4e4;
					}
					.total {
						font-size: 26px;
						font-family: 'hy';
						font-weight: 400;
						font-style: italic;
						color: #eba6c2;
					}
				}
			}
		}
	}
	.lineImage {
		height: 44vh;
	}
}
.el-button--primary {
	/* width: 68px; */
	height: 38px;
	background: rgba(255, 212, 228, 0.4);
	border: 1px solid #ffd4e4;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #eea8c4;
	border-radius: 0px;
	&.other {
		clip-path: polygon(
			0 0,
			100% 0,
			100% 0,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0 calc(100% + 12px),
			0 calc(100% + 12px),
			-2px -2px
		);
		background: linear-gradient(-45deg, #ffd4e4 8px, rgba(255, 212, 228, 0.4) 0) bottom right,
			linear-gradient(45deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) bottom left,
			linear-gradient(135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top left,
			linear-gradient(-135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top right;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}
::v-deep .el-dialog {
	min-height: 491.08px;
	.el-dialog__body {
		padding: 0 50px 50px;
	}
	.el-upload--picture-card {
		background-color: transparent;
		border: 1px dashed #ffd4e4;
		.el-icon-plus {
			color: #ffd4e4;
		}
	}
	.el-dialog__footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
	}
	.close-dialog {
		position: absolute;
		top: -16px;
		right: -16px;
		cursor: pointer;
		z-index: 2001;
	}
	.dialog-title {
		margin: 0 auto;
		min-width: 240px;
		width: max-content;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 62px;
		background: #cd80a2;
		border-radius: 0px 0px 14px 14px;
		background-size: 16px 16px;
		border: 2px solid #fcd2e2;
		margin-bottom: 30px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}
	.el-button--primary {
		width: 180px;
		height: 44px;
		background: #002953;
		border: 1px solid #ffd4e4;
		border-radius: 2px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
	.el-button + .el-button {
		margin-left: 78px;
	}
}

::v-deep .demo-form-inline {
	.el-form-item {
		margin-bottom: 0px;

		.el-form-item__label {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd4e3;
		}
		.el-input__inner,
		.el-textarea__inner {
			height: 38px;
			background: linear-gradient(270deg, rgba(205, 128, 162, 0), rgba(216, 149, 178, 0.17), rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			border-radius: 0;
			&::placeholder {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
			}
		}
	}
	&.dialog-form-inline {
		.el-form-item {
			width: 100%;
			display: flex;
			margin-bottom: 20px;

			.el-form-item__content {
				flex: 1;
			}
		}
	}
}
</style>
