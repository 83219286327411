<template>
	<div id="ring"></div>
</template>
<script>
import { Pie } from '@antv/g2plot';

export default {
	props: {
		census: {
			default: () => [],
			type: Array,
		},
		total: {
			default: 0,
			type: Number,
		},
	},
	data() {
		return {
			piePlot: null,
		};
	},
	methods: {
		initChart() {
			this.piePlot = new Pie('ring', {
				autoFit: true,
				data: this.census,
				angleField: 'value',
				colorField: 'type',
				renderer: 'svg',
				color: ['#8E1B46', '#FFB3CF'],
				pieStyle: {
					stroke: 'transparent',
					lineOpacity: 0,
					lineWidth: 0,
				},
				radius: 1,
				innerRadius: 0.7,
				label: {
					type: 'spider',
					content: (item) => item.type + item.value + '人',
					style: {
						fill: 'white',
					},
				},
				interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
				legend: {
					position: 'bottom',
					itemName: {
						style: {
							fill: 'white',
							fontFamily: 'Adobe Heiti Std',
						},
					},
					marker: {
						symbol: 'square',
					},
				},
				statistic: {
					title: {
						style: {
							whiteSpace: 'pre-wrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							fontFamily: 'hy',
							color: 'white',
							fontSize: 54,
							marginTop: '20px',
						},
						content: this.total,
					},
					content: {
						style: {
							whiteSpace: 'pre-wrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							color: 'white',
							fontFamily: 'Microsoft YaHei',
							fontSize: 23,
							marginTop: '23px',
						},
						content: '人',
					},
				},
			});
			this.piePlot.render();
		},
		changeStatus(i) {
			const piePlot = this.piePlot;
			if (i >= 1) {
				piePlot.elements[i].setState('active', true);
				piePlot.elements[i].stateStatus = true;
				piePlot.elements[i - 1].clearState();
				piePlot.elements[i - 1].stateStatus = false;
				const state = piePlot.elements[i].states[0];
				const stateStatus = piePlot.elements[i].stateStatus;
				let eledata = piePlot.elements[i].data;
				if (state === 'active') {
					if (stateStatus) {
						this.updateAnnotation(eledata);
					} else {
						this.clearAnnotation();
					}
				}
			} else {
				piePlot.elements[i].setState('active', true);
				piePlot.elements[i].stateStatus = true;
				piePlot.elements[4].clearState();
				piePlot.elements[4].stateStatus = false;
				const state = piePlot.elements[i].states[0];
				const stateStatus = piePlot.elements[i].stateStatus;
				let eledata = piePlot.elements[i].data;
				if (state === 'active') {
					if (stateStatus) {
						this.updateAnnotation(eledata);
					} else {
						this.clearAnnotation();
					}
				}
			}
		},
		updateAnnotation() {},
		clearAnnotation() {},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		census: {
			handler() {
				this.$nextTick(() => {
					this.piePlot && this.piePlot.destroy();
					this.initChart();
				});
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
#ring {
	height: 22vh;
	width: 100%;
}
</style>
