<template>
	<div class="pie-content" :id="id"></div>
</template>
<script>
import { Pie } from '@antv/g2plot';
export default {
	props: {
		pieData: {
			default: () => [],
			type: Array,
		},
		id: {
			default: '',
			type: String,
		},
		selected: {
			default: false,
			type: Boolean,
		},
		showLegend: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			piePlot: null,
		};
	},
	methods: {
		initChart() {
			this.piePlot = new Pie(this.id, {
				data: this.pieData,
				angleField: 'value',
				colorField: 'type',
				renderer: 'svg',
				radius: 0.8,
				color: this.pieData.map((item) => item.color),
				pieStyle: {
					stroke: 'transparent',
					lineOpacity: 0,
					lineWidth: 0,
				},
				label: {
					type: 'spider',
					content: (item) => item.type + item.value + '人',
					style: {
						fill: 'white',
					},
				},
				legend: !this.showLegend
					? false
					: {
							position: 'bottom',
							itemName: {
								style: {
									fill: 'white',
									fontFamily: 'Adobe Heiti Std',
								},
							},
							marker: {
								symbol: 'square',
							},
					  },
				// 自定义状态样式
				state: {
					active: {
						style: {
							lineWidth: 0,
							fillOpacity: 0.65,
						},
					},
				},
				// 添加 element 选中和激活交互
				interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
			});
			this.piePlot.render();
			this.selected && this.piePlot.setState('selected', () => true);
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		pieData: {
			handler() {
				this.$nextTick(() => {
					this.piePlot && this.piePlot.destroy();
					this.initChart();
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.pie-content {
	height: 26vh;
	width: 100%;
}
</style>
